<template>
  <div class="flex flex-col">
    <!-- Logo  -->
    <div class="flex flex-row">
      <NuxtImg
          :src="logoPath"
          width="250"
          class="mx-auto"
          alt=""
      />
    </div>
    <!-- Logo Ends -->
    <!-- Swiper -->
    <div class="swiper logoSwiper h-16 w-full">
      <div
          class="swiper-wrapper w-full"
      >
        <!-- Slides -->
        <div
            class="swiper-slide font-semibold text-2xl text-center"
        >
          Businesses
        </div>
        <div
            class="swiper-slide font-semibold text-2xl text-center"
        >
          Islamic Centers
        </div>
        <div
            class="swiper-slide font-semibold text-2xl text-center"
        >
          Classified
        </div>
        <div
            class="swiper-slide font-semibold text-2xl text-center"
        >
          News
        </div>
        <div
            class="swiper-slide font-semibold text-2xl text-center"
        >
          Fundraising
        </div>
      </div>
    </div>
    <!-- Swiper Ends -->
  </div>
</template>

<script
    setup
    lang="ts"
>
import {Autoplay} from 'swiper/modules';

const logoPath =
    "/images/main/logo.png";

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.logoSwiper', {
    modules: [Autoplay],
    direction: 'vertical',
    autoplay: {
      delay: 3000,
    },
    speed: "500",
    slidesPerView: "1",
    loop: true,
    effect: 'slide',
    allowTouchMove: "false",
    observer: true
  });
})
</script>

<style></style>
